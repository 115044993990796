import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from "constants/AuthConstant";
import clientService from "../../services/ClientService";
import { useDispatch } from "react-redux";
import masterService from "services/MasterService";

export const initialState = {
  loading: false,
  message: "",
  list: null,
};

export const workingdaysListAll = createAsyncThunk(
  "workingDays",
  async (data, { rejectWithValue }) => {
    try {
      const response = await masterService.getWorkingday();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const workingdaysSlice = createSlice({
  name: "workingdays",
  initialState,
  reducers: {
    workingdaysList: (state, action) => {
      state.loading = true;
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(workingdaysListAll.pending, (state) => {
        state.loading = false;
      })
      .addCase(workingdaysListAll.fulfilled, (state, action) => {
        state.loading = true;
        state.list = action.payload;
      });
  },
});

export const { workingdaysList } = workingdaysSlice.actions;

export default workingdaysSlice.reducer;
