import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from "constants/AuthConstant";
import clientService from "../../services/ClientService";
import { useDispatch } from "react-redux";
import masterService from "services/MasterService";

export const initialState = {
  loading: false,
  message: "",
  list: null,
};

export const statusListAll = createAsyncThunk(
  "status/list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await masterService.getStatus();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    statusList: (state, action) => {
      state.loading = true;
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(statusListAll.pending, (state) => {
        state.loading = false;
      })
      .addCase(statusListAll.fulfilled, (state, action) => {
        state.loading = true;
        state.list = action.payload;
      });
  },
});

export const { statusList } = statusSlice.actions;

export default statusSlice.reducer;
