import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import masterService from "services/MasterService";

export const initialState = {
  loading: false,
  message: "",
  list: null,
};

export const workingtimeListAll = createAsyncThunk(
  "workingtime/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await masterService.getWorkingtime();
      if (response.data) {
        return response.data;
      }
      throw new Error('No data received');
    } catch (err) {
      return rejectWithValue(err.message || "Error fetching working times");
    }
  }
);

export const workingtimeSlice = createSlice({
  name: "workingtime",
  initialState,
  reducers: {
    resetWorkingTime: (state) => {
      state.loading = false;
      state.list = null;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(workingtimeListAll.pending, (state) => {
        state.loading = true;
      })
      .addCase(workingtimeListAll.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload; // Make sure this aligns with how data is expected.
      })
      .addCase(workingtimeListAll.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload; // Error message is expected to be set in payload.
      });
  },
});

export const { resetWorkingTime } = workingtimeSlice.actions;

export default workingtimeSlice.reducer;
