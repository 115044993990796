import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from "constants/AuthConstant";
import clientService from "../../services/ClientService";
import { useDispatch } from "react-redux";
import masterService from "services/MasterService";

export const initialState = {
  loading: false,
  message: "",
  list: null,
  subloading: false,
  sublist:null,
};

export const categoryListAll = createAsyncThunk(
  "category/list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await masterService.getCategory();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const categorysubListAll = createAsyncThunk(
  "category/sublist",
  async (data, { rejectWithValue }) => {
    try {
      const response = await masterService.getCategory();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categoryList: (state, action) => {
      state.loading = true;
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(categoryListAll.pending, (state) => {
        state.loading = false;
      })
      .addCase(categoryListAll.fulfilled, (state, action) => {
        state.loading = true;
        state.list = action.payload;
      })
      .addCase(categorysubListAll.pending, (state) => {
        state.subloading = false;
      })
      .addCase(categorysubListAll.fulfilled, (state, action) => {
        state.subloading = true;
        state.sublist = action.payload;
      });
  },
});

export const { categoryList } = categorySlice.actions;

export default categorySlice.reducer;
