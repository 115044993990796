import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";

export const initialState = {
  loading: false,
  message: "",
  list: null,
  sublist: null,
  subloading: false,
};

export const logoListAll = createAsyncThunk(
  "index",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.listSetting(data);
   
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const logoSlice = createSlice({
  name: "logo",
  initialState,
  reducers: {
    logoList: (state, action) => {
      state.loading = true;
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoListAll.pending, (state) => {
        state.loading = false;
      })
      .addCase(logoListAll.fulfilled, (state, action) => {
        state.loading = true;
        state.list = action.payload;
      });
  },
});
export const { logoList } = logoSlice.actions;

export default logoSlice.reducer;
