import fetch from "auth/FetchMasterInterceptor";

const masterService = {};

// masterService.getCountry = function (data) {
//   return fetch({
//     url: "/country/list",
//     method: "post",
//     data: data,
//   });
// };
// masterService.addCountry = function (data) {
//   return fetch({
//     url: "/country/add",
//     method: "post",
//     data: data,
//   });
// };
// masterService.editCountry = function (data) {
//   return fetch({
//     url: "/country/update",
//     method: "post",
//     data: data,
//   });
// };
// masterService.deleteCountry = function (data) {
//   return fetch({
//     url: "/country/delete",
//     method: "post",
//     data: data,
//   });
// };

masterService.getUser = function (data) {
  return fetch({
    url: "/user/list",
    method: "post",
    data: data,
  });
};
masterService.addUser = function (data) {
  return fetch({
    url: "/user/add",
    method: "post",
    data: data,
  });
};
masterService.editUser = function (data) {
  return fetch({
    url: "/user/update",
    method: "post",
    data: data,
  });
};
masterService.deleteUser = function (data) {
  return fetch({
    url: "user/delete",
    method: "post",
    data: data,
  });
};
masterService.showUser = function (data) {
  return fetch({
    url: "user/show",
    method: "post",
    data: data,
  });
};

masterService.showDepartment = function (data) {
  return fetch({
    url: "/department/show",
    method: "post",
    data: data,
  });
};
masterService.getDepartment = function (data) {
  return fetch({
    url: "/department/list",
    method: "post",
    data: data,
  });
};
masterService.addDepartment = function (data) {
  return fetch({
    url: "/department/add",
    method: "post",
    data: data,
  });
};
masterService.editDepartment = function (data) {
  return fetch({
    url: "/department/update",
    method: "post",
    data: data,
  });
};
masterService.deleteDepartment = function (data) {
  return fetch({
    url: "department/delete",
    method: "post",
    data: data,
  });
};

masterService.getCategory = function (data) {
  return fetch({
    url: "/category/list",
    method: "post",
    data: data,
  });
};
masterService.addCategory = function (data) {
  return fetch({
    url: "/category/add",
    method: "post",
    data: data,
  });
};
masterService.editCategory = function (data) {
  return fetch({
    url: "/category/update",
    method: "post",
    data: data,
  });
};
masterService.deleteCategory = function (data) {
  return fetch({
    url: "category/delete",
    method: "post",
    data: data,
  });
};

masterService.getPriority = function (data) {
  return fetch({
    url: "/priority/list",
    method: "post",
    data: data,
  });
};
masterService.addPriority = function (data) {
  return fetch({
    url: "/priority/add",
    method: "post",
    data: data,
  });
};
masterService.editPriority = function (data) {
  return fetch({
    url: "/priority/update",
    method: "post",
    data: data,
  });
};
masterService.deletePriority = function (data) {
  return fetch({
    url: "priority/delete",
    method: "post",
    data: data,
  });
};

masterService.getStatus = function (data) {
  return fetch({
    url: "/status/list",
    method: "post",
    data: data,
  });
};
masterService.addStatus = function (data) {
  return fetch({
    url: "/status/add",
    method: "post",
    data: data,
  });
};
masterService.editStatus = function (data) {
  return fetch({
    url: "/status/edit",
    method: "post",
    data: data,
  });
};
masterService.deleteStatus = function (data) {
  return fetch({
    url: "status/delete",
    method: "post",
    data: data,
  });
};

masterService.getTicket = function (data) {
  return fetch({
    url: "/ticket/list",
    method: "post",
    data: data,
  });
};
masterService.addTicket = function (data) {
  return fetch({
    url: "/ticket/create",
    method: "post",
    data: data,
  });
};
masterService.editTicket = function (data) {
  return fetch({
    url: "/ticket/update",
    method: "post",
    data: data,
  });
};
masterService.deleteTicket = function (data) {
  return fetch({
    url: "ticket/delete",
    method: "post",
    data: data,
  });
};
masterService.getUser = function (data) {
  return fetch({
    url: "/user/list",
    method: "post",
    data: data,
  });
};

masterService.getPriority = function (data) {
  return fetch({
    url: "/priority/list",
    method: "post",
    data: data,
  });
};

masterService.getStatus = function (data) {
  return fetch({
    url: "/status/list",
    method: "post",
    data: data,
  });
};

masterService.editTicket = function (data) {
  return fetch({
    url: "/ticket/update",
    method: "post",
    data: data,
  });
};
  
masterService.editReassign = function (data) {
  return fetch({
    url: "/ticket/assign",
    method: "post",
    data: data,
  });
};

masterService.getAssettype = function (data) {
  return fetch({
    url: "/asset/list",
    method: "post",
    data: data,
  });
};

masterService.addAssettype = function (data) {
  return fetch({
    url: "/asset/create",
    method: "post",
    data: data,
  });
};

masterService.editAssettype = function (data) {
  return fetch({
    url: "/asset/update",
    method: "post",
    data: data,
  });
};

masterService.deleteAssettype = function (data) {
  return fetch({
    url: "/asset/delete",
    method: "post",
    data: data,
  });
};

masterService.getApplication = function (data) {
  return fetch({
    url: "/applicationForm/list",
    method: "post",
    data: data,
  });
};

masterService.addApplication = function (data) {
  return fetch({
    url: "/applicationForm/store",
    method: "post",
    data: data,
  });
};

masterService.editApplication = function (data) {
  return fetch({
    url: "/applicationForm/update",
    method: "post",
    data: data,
  });
};

masterService.deleteApplication = function (data) {
  return fetch({
    url: "/applicationForm/delete",
    method: "post",
    data: data,
  });
};

masterService.getTaskstatus = function (data) {
  return fetch({
    url: "/taskStatus/list",
    method: "post",
    data: data,
  });
};

masterService.updateTaskstatus = function (data) {
  return fetch({
    url: "/taskStatus/update",
    method: "post",
    data: data,
  });
};

masterService.getUserAsset = function (data) {
  return fetch({
    url: "/user-asset/list",
    method: "post",
    data: data,
  });
};
masterService.addUserAsset = function (data) {
  return fetch({
    url: "/user-asset/create",
    method: "post",
    data: data,
  });
};
masterService.editUserAsset = function (data) {
  return fetch({
    url: "/user-asset/update ",
    method: "post",
    data: data,
  });
};
masterService.deleteUserAsset = function (data) {
  return fetch({
    url: "/user-asset/delete",
    method: "post",
    data: data,
  });
};

masterService.showUserAsset = function (data) {
  return fetch({
    url: "/user-asset/show",
    method: "post",
    data: data,
  });
};

masterService.statusUpdateAssignAsset = function (data) {
  return fetch({
    url: "/user-asset/assignAsset",
    method: "post",
    data: data,
  });
};

masterService.DiscardAsset = function (data) {
  return fetch({
    url: "/user-asset/discard",
    method: "post",
    data: data,
  });
};

masterService.getRole = function (data) {
  return fetch({
    url: "/role/list",
    method: "post",
    data: data,
  });
};
masterService.addRole = function (data) {
  return fetch({
    url: "/role/add",
    method: "post",
    data: data,
  });
};
masterService.assignRole = function (data) {
  return fetch({
    url: "/role/assignPermission",
    method: "post",
    data: data,
  });
};
masterService.deleteRole = function (data) {
  return fetch({
    url: "/role/delete",
    method: "post",
    data: data,
  });
};

masterService.editRole = function (data) {
  return fetch({
    url: "/role/update",
    method: "post",
    data: data,
  });
};

masterService.getPermission = function (data) {
  return fetch({
    url: "/permission/list",
    method: "post",
    data: data,
  });
};
masterService.addPermission = function (data) {
  return fetch({
    url: "/permission/add",
    method: "post",
    data: data,
  });
};
masterService.editPermission = function (data) {
  return fetch({
    url: "/permission/update",
    method: "post",
    data: data,
  });
};
masterService.deletePermission = function (data) {
  return fetch({
    url: "/permission/delete",
    method: "post",
    data: data,
  });
};

masterService.getDashboard = function (data) {
  return fetch({
    url: "/dashboard/list",
    method: "post",
    data: data,
  });
};

masterService.getEmpDashboard = function (data) {
  return fetch({
    url: "/dashboard/user",
    method: "post",
    data: data,
  });
};
masterService.statusUpdate = function (data) {
  return fetch({
    url: "/status/update",
    method: "post",
    data: data,
  });
};

masterService.editWorkingday = function (data) {
  return fetch({
    url: "/workingday/store",
    method: "post",
    data: data,
  });
};
masterService.getWorkingday = function (data) {
  return fetch({
    url: "/workingDays",
    method: "post",
    data: data,
  });
};
// masterService.getWorkingtime = function (data) {
//   return fetch({
//     url: "/workingTime",
//     method: "post",
//     data: data,
//   });
// };

masterService.notification = function (data) {
  return fetch({
    url: "/notification/list",
    method: "post",
    data: data,
  });
};
masterService.deleteNotification = function (data) {
  return fetch({
    url: "/notification/delete",
    method: "post",
    data: data,
  });
};
// masterService.updateWorkingtime = function (data) {
//   return fetch({
//     url: "/workingTime/store",
//     method: "post",
//     data: data,
//   });
// };

export default masterService;
