import fetch from "auth/FetchMasterInterceptor";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "constants/AuthConstant";


const SettingService = {};
const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
SettingService.getMenu = function (data) {
  return fetch({
    url: "/setting/menu",
    method: "post",
    data: data,
  });
};

SettingService.getUser = function (data) {
  return fetch({
    url: "/user/list",
    method: "post",
    data: data,
  });
};

SettingService.getDetails = function (data) {
  return fetch({
    url: "/user/details",
    method: "post",
    data: data,
  });
};

SettingService.updateDetails = function (data) {
  return fetch({
    url: "/user/update",
    method: "post",
    data: data,
  });
};

SettingService.AddUser = function (data) {
  return fetch({
    url: "/user/create",
    method: "post",
    data: data,
  });
};

SettingService.deleteUser = function (data) {
  return fetch({
    url: "/user/delete",
    method: "post",
    data: data,
  });
};

SettingService.updateUser = function (data) {
  return fetch({
    url: "/user/edit",
    method: "post",
    data: data,
  });
};

//update permission status active / inactive
SettingService.updatePermission = function (data) {
  return fetch({
    url: "/permission/update",
    method: "post",
    data: data,
  });
};

SettingService.notification = function (data) {
  return fetch({
    url: "/notification/list",
    method: "post",
    data: data,
  });
};

SettingService.updateSetting = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return axios.post(`${API_BASE_URL}/sitesetting/update`, data, config);
};


SettingService.updateSettingWorkingdays = function (data) {
  return fetch({
    url: "/sitesetting/update",
    method: "post",
    data: data,
  });
};

SettingService.getWorkingtime = function (data) {
  return fetch({
    url: "/workingTime",
    method: "post",
    data: data,
  });
};

SettingService.listSetting = function (data) {
  return fetch({
    url: "/index",
    method: "post",
    data: data,
  });
};

SettingService.uploadimportfile = function (data) {
  return fetch({
    url: "/user-asset/import",
    method: "post",
    data: data,
  });
};


export default SettingService;
