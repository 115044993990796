import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import logo from './slices/logoSlice'
import  department  from './slices/departmentSlice'
import  category  from './slices/categorySlice'
import priority from './slices/prioritySlice'
import status from './slices/statusSlice'
import user from './slices/user'
import asset from './slices/assetSlice'
import  trash  from './slices/trashassetSlice'
import ticket from './slices/ticketSlice'
import application from './slices/applicationSlice'
import admindashboard from './slices/admindashboardSlice'
import empdashboard from './slices/empdashboardSlice'
import  workingdays from './slices/workingdaysSlice'
import  workingtime from './slices/workingtimeSlice'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        logo,
        department,
        category,
        priority,
        status,
        user,
        asset,
        trash,
        ticket,
        application,
        admindashboard,
        empdashboard,
        workingdays,
        workingtime,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
