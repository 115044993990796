import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/Ticketmodel/dashboards/default")),
  },
  {
    key: "dashboard.empdashboard",
    path: `${APP_PREFIX_PATH}/dashboards/empdashboard`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/empdashbord/index")
    ),
  },

  {
    key: "dashboard.department",
    path: `${APP_PREFIX_PATH}/dashboards/department`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Master/department/index")
    ),
  },
  {
    key: "dashboard.category",
    path: `${APP_PREFIX_PATH}/dashboards/category`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Master/category")
    ),
  },
  {
    key: "dashboard.priority",
    path: `${APP_PREFIX_PATH}/dashboards/priority`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Master/priority")
    ),
  },
  {
    key: "dashboard.status",
    path: `${APP_PREFIX_PATH}/dashboards/status`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Master/status/index")
    ),
  },
  {
    key: "dashboard.assettype",
    path: `${APP_PREFIX_PATH}/dashboards/assettype`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Master/assettype/index")
    ),
  },
  {
    key: "dashboard.fileupload",
    path: `${APP_PREFIX_PATH}/dashboards/fileupload`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Master/fileupload/fileupload")
    ),
  },
  {
    key: "dashboard.userasset",
    path: `${APP_PREFIX_PATH}/dashboards/userasset`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/userasset/userasset")
    ),
  },
  {
    key: "dashboard.trashedasset",
    path: `${APP_PREFIX_PATH}/dashboards/trashedasset`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/trashedassets/index")
    ),
  },

  {
    key: "dashboard.forms",
    path: `${APP_PREFIX_PATH}/dashboards/userasset/:user_id`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/userasset/form")
    ),
  },
  // {
  //   key: "dashboard.formss",
  //   path: `${APP_PREFIX_PATH}/dashboards/userasset/:user_id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/dashboards/userasset/form")
  //   ),
  // },

  {
    key: "dashboard.ticketlist",
    path: `${APP_PREFIX_PATH}/dashboards/ticketlist`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Ticketlist/index")
    ),
  },

  {
    key: "dashboard.createticket",
    path: `${APP_PREFIX_PATH}/dashboards/ticketlist/create/`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Ticketlist/ticketform")
    ),
  },

  {
    key: "dashboard.application",
    path: `${APP_PREFIX_PATH}/dashboards/application`,
    component: React.lazy(() => import("views/app-views/Ticketmodel/dashboards/application/index")),
  },

  
  {
    key: "dashboard.comment",
    path: `${APP_PREFIX_PATH}/dashboards/comment`,
    component: React.lazy(() => import("views/app-views/Ticketmodel/dashboards/Comment/index")),
  },


  

  {
    key: "dashboard.ticketstatus",
    path: `${APP_PREFIX_PATH}/dashboards/ticketstatus`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/ticketstatus/index")
    ),
  },
  {
    key: "dashboard.reassigneticket",
    path: `${APP_PREFIX_PATH}/dashboards/reassigneticket`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/reassigneticket/index")
    ),
  },
  {
    key: "dashboard.reopenticket",
    path: `${APP_PREFIX_PATH}/dashboards/reopenticket`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/reopenticket/index")
    ),
  },
  {
    key: "dashboard.task",
    path: `${APP_PREFIX_PATH}/dashboards/task`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/Task/index")
    ),
  },
  
  {
    key: "dashboard.role",
    path: `${APP_PREFIX_PATH}/dashboards/role`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/settings/role/index")
    ),
  },
  {
    key: "dashboard.permission",
    path: `${APP_PREFIX_PATH}/dashboards/permission`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/settings/permission")
    ),
  },
  {
    key: "dashboard.rolepermission",
    path: `${APP_PREFIX_PATH}/dashboards/rolepermission/:role_id`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/settings/role/permission")
    ),
  },
  {
    key: "dashboard.updates",
    path: `${APP_PREFIX_PATH}/dashboards/updates`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/site_setting/update")
    ),
  },

  { 
    key: "dashboard.termandcondition",
    path: `${APP_PREFIX_PATH}/dashboards/termandcondition`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/site_setting/termandcondition")
    ),
  },
  {
    key: "dashboard.privacypolicy",
    path: `${APP_PREFIX_PATH}/dashboards/privacypolicy`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/site_setting/privacypolicy")
    ),
  },
  { 
    key: "dashboard.termandcondition",
    path: `${APP_PREFIX_PATH}/dashboards/terms`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/settings/termandconditionshow")
    ),
  },
  {
    key: "dashboard.privacypolicy",
    path: `${APP_PREFIX_PATH}/dashboards/privacy`,
    component: React.lazy(() =>
      import("views/app-views/Ticketmodel/dashboards/settings/privacypolicyshow")
    ),
  },
  // {
  //   key: "dashboard.working_days",
  //   path: `${APP_PREFIX_PATH}/dashboards/working_days`,
  //   component: React.lazy(() =>
  //     import("views/app-views/dashboards/site_setting/working_days")
  //   ),
  // },
  // {
  //   key: "dashboard.working_hours",
  //   path: `${APP_PREFIX_PATH}/dashboards/working_hours`,
  //   component: React.lazy(() =>
  //     import("views/app-views/dashboards/site_setting/working_hours")
  //   ),
  // },
  
];
